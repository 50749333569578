import { useSelector } from 'react-redux';
import { ReduxState } from '../../../types/redux';
import { PARTNER_PLATFORM_URL } from '../../../utils/constants';
import {
  MCSpacing,
  MCTwoPaneLayout,
} from '../../../_shared/components';
import { useLeftNavigatorOwnerProps } from '../../hooks';
import styles from './Invoices.module.css';

export const Invoices = () => {
  const props = useLeftNavigatorOwnerProps();

  const token = useSelector(
    (x: ReduxState) => x.userReducer.token || ''
  );

  console.log({url: PARTNER_PLATFORM_URL + '/invoices?authToken=' + token});
  return (
    <MCTwoPaneLayout title="Facturi" {...props}>
      <MCSpacing />
      <MCSpacing />
      <MCSpacing />
      <div className={styles.mainContainer}>
        <iframe 
          title='Invoices'
          src={PARTNER_PLATFORM_URL + "invoices?authToken=" + token}
          height={900}
          width={"100%"}
        />
      </div>
    </MCTwoPaneLayout>
  );
};
