import { TeamSettings } from '../types/api';
import axios from '../utils/axios';
import { BASE_URL } from '../utils/constants';

export const updateTeamSettings = async (teamSettings: TeamSettings) => {
  try {
    return await axios.put(`${BASE_URL}/team-settings`, teamSettings);
  } catch (err) {
    console.error('Failed to put teamSettings');
    console.error(err);
    throw err;
  }
};
